<template>
    <div class="test-container">
        <el-divider content-position="left">test...</el-divider>
        <el-row>
            <el-input v-model="firstName"></el-input>
            <el-input v-model="lastName"></el-input>

            <em>{{fullName}}</em>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: 'setting',
        data() {
            return {
                show: true,
                token: this.$store.getters['user/token'],
                firstName: 'YueXiong',
                lastName: 'Dai'
            }
        },
        computed: {
            fullName() {
                return this.firstName + this.lastName
            }
        },
        watch: {
            firstName: {
                handler(n, o) {
                    console.log(`[watch firstName] {NewValue:${n},OldValue:${o}}`);
                }
            },
            lastName: {
                handler(n, o) {
                    console.log(`[watch lastName] {NewValue:${n},OldValue:${o}}`);
                }
            }
        },
        created() {
            //console.log('[created]');
        },
        mounted() {
            //console.log('[mounted]');
        },
        methods: {},
    }
</script>